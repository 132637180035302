<template>
  <div class="Login blank-page" data-col="blank-page">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header row"></div>
        <div class="content-body">
          <div class="auth-wrapper auth-cover">
            <div class="auth-inner row m-0">
              <!-- Brand logo-->

              <div class="row">
                <div class="col-xl-8" style="background-color: #ffffff">
                  <img
                    style="position: absolute; top: 5px; left: 5px; width: 150px"
                    src="/app-assets/images/vakilgiri-logo.png"
                    alt="logo"
                  />
                  <div
                    class="container d-none d-xl-block d-md-block d-lg-block text-center"
                    style="padding: 0px 100px 0px 100px"
                  >
                    <!-- ddddd -->

                    <div
                      id="carouselExampleControls"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            src="/app-assets/images/Team-pana.png"
                            class="d-block w-100"
                            alt="image1"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img
                            src="/app-assets/images/slider-1.png"
                            class="d-block w-100"
                            alt="image2"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                        <div class="carousel-item">
                          <img
                            src="/app-assets/images/slider-4.png"
                            class="d-block w-100"
                            alt="image3"
                          />
                          <div class="d-block text-center">
                            <h4>Lorem, ipsum dolor.</h4>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Expedita, voluptate odit! Sint suscipit enim iste facere vero
                            debitis provident ratione temporibus nihil natus, at quos
                            ducimus tempora aliquam aliquid deserunt.
                          </div>
                        </div>
                      </div>
                      <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>

                    <!-- ddddddd -->
                  </div>
                </div>
                <div class="col-xl-4 p-5 text-white" style="background-color: #00364f">
                  <p class="mt-3 d-block d-xl-none d-md-none d-lg-none"></p>
                  <h2 class="card-title fw-bold mb-1 text-white">Reset Password 🔒</h2>
                  <p class="card-text mb-2">
                    Your new password must be different from previously used passwords
                  </p>
                  <div v-if="msgError">
                    <div class="alert alert-danger p-1" role="alert">
                      <small>{{ msgError.message }}</small>
                    </div>
                  </div>

                  <form class="auth-login-form mt-2" @submit.prevent="resetPassword">
                    <div class="mb-1">
                      <label class="form-label text-white" for="login-email"
                        >New Password</label
                      >
                      <input
                        class="form-control"
                        v-if="showPassword"
                        type="text"
                        v-model="form.password"
                        placeholder="........"
                      />
                      <input
                        class="form-control"
                        v-else
                        type="password"
                        v-model="form.password"
                        placeholder="........"
                      />
                    </div>
                    <div class="mb-1">
                      <div class="d-flex justify-content-between">
                        <label class="form-label text-white" for="login-password">
                          Confirm Password</label
                        >
                      </div>
                      <div class="input-group input-group-merge form-password-toggle">
                        <input
                          class="form-control form-control-merge"
                          id="login-password"
                          v-if="showPassword"
                          type="text"
                          v-model="form.confirm_password"
                          placeholder="············"
                        />
                        <input
                          class="form-control form-control-merge"
                          id="login-password"
                          v-else
                          type="password"
                          v-model="form.confirm_password"
                          placeholder="············"
                        />
                      </div>
                    </div>
                    <!-- <div class="mb-1">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          id="remember-me"
                          v-model="status"
                          type="checkbox"
                          tabindex="3"
                        />
                        <label class="form-check-label text-white" for="remember-me">
                          Remember Me</label
                        >
                      </div>
                    </div> -->
                    <span @click="toggleShow" class="text-white cursor-pointer">
                      <img
                            v-if="showPassword"
                            style="width: 30px;background-color:white;padding:5px;border-radius:5px"
                            src="/assets/image/view.png"
                            alt="image2" />
                            <img
                            v-else
                            style="width: 30px;background-color:white;padding:5px;border-radius:5px"
                            src="/assets/image/hide.png"
                            alt="image2"
                        />
                            
                            &nbsp;&nbsp; Show password
                    </span>
                    <button
                      type="submit"
                      class="btn btn-sm w-100 text-white mt-1"
                      style="background-color: #f21300; box-shadow: 1px 1px 3px #f21000"
                    >
                      Reset
                    </button>
                  </form>
                  <p class="text-center mt-2">
                    <span>Go Back</span>
                    <router-link to="/retailer/login"
                      ><span style="color: #f21300">&nbsp;Login</span></router-link
                    >
                    <!-- <a href="#"></a> -->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Login",
  //   components: {
  //     HelloWorld
  //   }
  data() {
    return {
      showPassword: false,
      password: null,
      form: {
        random: "",
        password: "",
        confirm_password: "",
      },
      msgError: "",
      status: "",
      userEmail: "",
      userLoginData: {},
    };
  },
  created() {
    localStorage.setItem("accessToken", "reset");
    console.log(this.$route.query.random);
    this.form.random = this.$route.query.random;
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    resetPassword() {
      this.$axios
        .post("retailer/reset-password", this.form)
        .then((res) => {
          console.log(res.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Reset Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.msgError = "";
          // this.$router.go({ path: "/retailer/home" });
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          // error.data.message;
          this.msgError = error.response.data;
          console.log(error.response.data);
        });
    },
  },
};
</script>
